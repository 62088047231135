import LocalStorage from "utils/Storage/LocalStorage";
import { ITokenInfo } from "./tokenType";
import { navigateToPage } from "utils/navigateToPage";
import { REDIRECTION } from "utils/UrlList";
import { IAuthCodes } from "utils/strings";

export const TokenLocalStorage = new LocalStorage<ITokenInfo>(
  "TokenLocalStorage"
);

export const sessionClear = () => {
  const tokenInfo = TokenLocalStorage.get();
  if (!tokenInfo) return undefined;
  TokenLocalStorage.clear();
  return tokenInfo;
};

export const authFailedPostCallback = async (code: IAuthCodes) => {
  sessionClear();
  navigateToPage(
    `${REDIRECTION.failureAuthenticationOrLogoutRedirection}?code=${code}`
  );
};
