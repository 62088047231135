import LayoutRouter, { IPropsLayout } from "./LayoutRouter";
import AuthLayout from "components/layouts/AuthLayout";
import LoadingPage from "components/core/Loaders/LoadingPage";
import { useAuth } from "common/hooks/session/useAuth";
import { PropsWithChildren, useEffect } from "react";
import { authFailedPostCallback } from "services/auth/token/tokenStorage";

const PrivateRoutes = ({
  children,
  Layout = AuthLayout,
}: PropsWithChildren<IPropsLayout>) => {
  const { loading, isLoggedIn, error } = useAuth();

  useEffect(() => {
    if (!loading) {
      if (!isLoggedIn) {
        authFailedPostCallback("auth_failed");
      }
    }
  }, [error, isLoggedIn, loading]);

  if (loading || !isLoggedIn) return <LoadingPage />;

  return <LayoutRouter Layout={Layout}>{children}</LayoutRouter>;
};

export default PrivateRoutes;
