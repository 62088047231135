import { useMemo, useCallback } from "react";

interface RedirectData {
  count: number;
  timestamp: number;
}

interface UseRedirectionLimitOptions {
  maxRedirections: number; // Maximum allowed redirections
  timeLimit: number; // Time window in milliseconds
  localStorageKey?: string; // Custom localStorage key (default: 'redirectData')
}

interface UseRedirectionLimitResult {
  canRedirect: boolean; // Whether redirection is allowed
  updateRedirectionCount: (target: string) => void; // Function to update redirection count and optionally redirect
}

const useRedirectionLimit = ({
  maxRedirections,
  timeLimit,
  localStorageKey = "redirectData",
}: UseRedirectionLimitOptions): UseRedirectionLimitResult => {
  const redirectData = useMemo(() => {
    const storedData = localStorage.getItem(localStorageKey);
    return storedData
      ? (JSON.parse(storedData) as RedirectData)
      : { count: 0, timestamp: Date.now() };
  }, [localStorageKey]);

  const canRedirect = useMemo(() => {
    const currentTime = Date.now();
    const timeElapsed = currentTime - redirectData.timestamp;

    if (timeElapsed > timeLimit) {
      // Reset if time limit has passed
      return true;
    }
    return redirectData.count < maxRedirections;
  }, [redirectData.timestamp, redirectData.count, timeLimit, maxRedirections]);

  const updateRedirectionCount = useCallback(() => {
    const currentTime = Date.now();
    const timeElapsed = currentTime - redirectData.timestamp;

    let updatedRedirectData: RedirectData;

    if (timeElapsed > timeLimit) {
      // Reset if time limit has passed
      updatedRedirectData = { count: 1, timestamp: currentTime };
    } else {
      // Increment count within the same time window
      updatedRedirectData = {
        count: redirectData.count + 1,
        timestamp: redirectData.timestamp,
      };
    }

    // Save updated data to localStorage
    localStorage.setItem(localStorageKey, JSON.stringify(updatedRedirectData));
  }, [redirectData, timeLimit, localStorageKey]);

  return { canRedirect, updateRedirectionCount };
};

export default useRedirectionLimit;
